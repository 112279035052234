#impressum,#datenschutz {
  height: inherit;
  h1,h2,h3,h4,h5,h6{
    //alle headlines
  }
  p {
    color:black;
    padding: 0;
    font-family:$bFutura;
    letter-spacing: 0.127em;
    line-height: 1.8em;
    margine: 0 0 20 0;
    font-size: 1rem;
  }

  ul li {
    font-family: $fFelix;
    font-size: 1.6vw;
    letter-spacing: 0.139em;
    line-height: 2em;
  }
}


.impText {
  max-width: 65em;
  width: 70%;
  margin: 0 auto;
  padding-bottom: 2rem;

  h4 {
    margin: 1.5rem 0 .5rem 0;
  }
}

#impText-heading p.heading {
  margin:0 0 .5rem 0;
}

.impTextLogo {
  max-width: 50rem;
  margin: 0 auto;
  padding: 4rem 0 4rem;
}

.impTextLogo a {
  display: flex;
  justify-content: center;
}

.impContainer {
  margin: 0 2rem 0 2rem;
}

@include bp(mobile){
  .impText {
    width: 90%
  }
}
@include bp(ipadV){
  .impText {
    max-width: 80%;
  }
}

@include bp(ipadH){
  .impText {
    max-width: 60%;
  }
}
