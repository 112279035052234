@font-face{
    font-family:"Felix Titling MT W00 Regular";
    src:url("../assets/fonts/fd4ce672-deac-439e-8c5d-24c7667faa59.eot?#iefix");
    src:url("../assets/fonts/fd4ce672-deac-439e-8c5d-24c7667faa59.eot?#iefix") format("eot"),url("../assets/fonts/bb0068ac-3f5b-4c8b-b07d-d9f7f4b46466.woff2") format("woff2"),url("../assets/fonts/1cdd0099-fe3c-4bdb-bd85-bc00ea00507b.woff") format("woff"),url("../assets/fonts/7dc94a8a-de64-44b0-a1bb-67e6914b7832.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family:"Futura LT W01 Bold";
    src:url("../assets/fonts/87f56fc1-ab05-4355-90af-9e83740663af.eot?#iefix");
    src:url("../assets/fonts/87f56fc1-ab05-4355-90af-9e83740663af.eot?#iefix") format("eot"),url("../assets/fonts/3cf9e1e8-63a2-497f-86ed-6b63d6de1986.woff2") format("woff2"),url("../assets/fonts/184895ac-545b-450b-9d3a-c0a0a7620977.woff") format("woff"),url("../assets/fonts/bf8628d2-1ae0-4645-ba3f-3ce62d8d907c.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
@font-face{
       font-family:"Futura LT W01 Book";
       src:url("../assets/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix");
       src:url("../assets/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"),url("../assets/fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"),url("../assets/fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"),url("../assets/fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype");
   }


$fFelix: "Felix Titling MT W00 Regular";
$fFutura: "Futura LT W01 Bold";
$bFutura: "Futura LT W01 Book";

h1,h2,h3,h4,h5,h6,p {
    margin:0 ;
    padding:0;
}

h1{
  font-family:$fFutura;
  letter-spacing: 0.127em;
  line-height: 1em;
  margin: 50px 0px 30px 0px;
  font-size: 1rem;
}

h2{
  font-family:$fFelix;
  letter-spacing: 0.139em;
  font-weight: normal;
  font-feature-settings: "liga" 0;
  line-height: 2em;
  margin: 45px 0px 20px 0px;
  font-size: 1.5rem;
}

h3{
  font-family:$fFelix;
  letter-spacing: 0.139em;
  line-height: 2em;
  font-weight: normal;
  font-feature-settings: "liga" 0;
  margin: 35px 0px 15px 0px;
  font-size: 1.5rem;
}

h4{
  font-family:$bFutura;
  letter-spacing: 0.127em;
  line-height: 2em;
  font-size: 1rem;
}

h6, footer .link {
  font-family: $fFutura;
  letter-spacing: 0.139em;
  // line-height: 2em;
  // font-size: 1.6vw;
  letter-spacing: 0.127em;
  font-size: 1rem;


  @include bp(mobile){
    font-size: 0.85rem;
  }
}

p {
  font-family: $fFelix;

  font-size: 2vw;
  letter-spacing: 0.139em;
  line-height: 2em;
  margin: 0 0 20 0;
  @include bp(mobile){
    font-size: 1rem;
  }
}
