

.links--wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  &:first-of-type {
    justify-content: space-between;
  }
  &:last-of-type {
    .link {
      font-family: $fFelix;
      font-size: 0.8rem;
    }
  }
  .link {
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    &:last-of-type {
      margin-right: 0;
    }
    span {
      position: relative;
      margin-right: 0.75rem;
      margin-left: 0.2rem;
      &:after {
        content: ":";
        display: block;
        position: absolute;
        top: 0.25em;
        left: 0;
      }
    }
  }
}
