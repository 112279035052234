#content--wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  > div {
  min-height: 100vh;
  }
}


#content {
  width: 78vw;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include bp(mobile){
    width: 85vw;
  }
  header, section, footer {
    padding: 6vh 12vw 6vh 4vw;
    @include bp(mobile){
      padding-right: 3vw;
    }
  }
  header {
  }
  section {
    @include bp(mobile){
      margin: 3rem 0;
    }
    p {

      &:last-of-type {
        display: none;
        margin-top: 3rem;
        font-size: 0.8rem;
        @include bp(mobile){
          display: block;
        }
        @include bp(ipadV){
          display: block;
        }
      }
    }
  }
  footer {
    max-width: 1000px;
  }
}


#pattern--wrapper {
  width: 22vw;
  display: flex;
  position:relative;
  overflow: hidden;
  @include bp(mobile){
    width: 15vw;
  }
  img {
    &:first-of-type {
      display: block;
      position: absolute;
      bottom:0;
      width: 100%;
      @include bp(mobile){
        display: none;
      }
    }
    &:last-of-type {
      display: none;
      position: fixed;
      width: 15vw;
      @include bp(mobile){
        display: block;
      }
    }
  }
}

#address--wrapper {
  position: absolute;
  bottom: 0;
  left:0;
  margin-bottom: calc(6vh + 1rem);
  p {
    font-size: 0.82vw;
  }
  @include bp(mobile){
    display: none;
  }
  @include bp(ipadV){
    display: none;
  }
}

@include bp(mobile){

}


@include bp(ipadV){

}

@include bp(ipadH){

}

@include bp(desktop){

}


header, section, footer, #pattern--wrapper img, #address--wrapper {
  opacity: 0;
}
$trans--time: 1.5s;

body.active {
  header {
    opacity: 1;
    transition: $trans--time opacity  ;
  }
  #pattern--wrapper img {
    opacity: 1;
    transition: $trans--time opacity 1s;
  }
  section {
    opacity: 1;
    transition: $trans--time opacity 2s;
  }
  footer, #address--wrapper {
    opacity: 1;
    transition: $trans--time opacity 3s;
  }
}
